.App {
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.limite-card {
  display: inline-block;
  width: 180px;
  height: 50px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.font-9{
  font-size: 12px !important;
}

.center-div{
  text-align: center;
}
.red{
  color: red;
}
.pointer{
  cursor: pointer;
}
.logoIESTP{
 height: 35px;
  padding: 5px;
  border-radius: 8px;
}


.wrapper {
  background-color: whitesmoke;
  padding: 20px 0;
}

.box {
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 10px;
  color: #000;
  background-color: #fff;
}

.center-align {
  text-align: center;
  font-size: 0;
}

  .center-align [class*='col-'] {
      display: inline-block;
      vertical-align: top;
      letter-spacing: 0;
      font-size: 14px;
      float: none;
  }


.login-container {
  margin-top: 10%;
  border: 1px solid #CCD1D1;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 50%;
}

.ads {
  background-color: #A569BD;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  padding: 15px;
  text-align: center;
}

.ads h1 {
  margin-top: 20%;
}

#fl {
  font-weight: 600;
}

#sl {
  font-weight: 100 !important;
}

.profile-img {
  text-align: center;
}

.profile-img img {
  border-radius: 50%;
  /* animation: mymove 2s infinite; */
}

@keyframes mymove {
  from {border: 1px solid #F2F3F4;}
  to {border: 8px solid #F2F3F4;}
}

.login-form {
  padding: 15px;
}

.login-form h3 {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
